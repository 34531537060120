// extracted by mini-css-extract-plugin
export var bgGrey100 = "TwoImagesWithLogoOption-module--bg-grey-100--a9e8e";
export var bgGrey150 = "TwoImagesWithLogoOption-module--bg-grey-150--11a67";
export var bgGrey200 = "TwoImagesWithLogoOption-module--bg-grey-200--3c5c6";
export var bgGrey300 = "TwoImagesWithLogoOption-module--bg-grey-300--a519d";
export var bgGrey400 = "TwoImagesWithLogoOption-module--bg-grey-400--c1c56";
export var bgGrey500 = "TwoImagesWithLogoOption-module--bg-grey-500--32429";
export var bgGrey600 = "TwoImagesWithLogoOption-module--bg-grey-600--5142e";
export var bgGrey700 = "TwoImagesWithLogoOption-module--bg-grey-700--4a876";
export var bgGrey800 = "TwoImagesWithLogoOption-module--bg-grey-800--20a12";
export var bgGrey900 = "TwoImagesWithLogoOption-module--bg-grey-900--647e9";
export var imageContainer = "TwoImagesWithLogoOption-module--imageContainer--5e6ce";
export var logoContainer = "TwoImagesWithLogoOption-module--logoContainer--adaad";
export var subheadingCls = "TwoImagesWithLogoOption-module--subheadingCls--b5635";
export var textGrey100 = "TwoImagesWithLogoOption-module--text-grey-100--b5f3a";
export var textGrey150 = "TwoImagesWithLogoOption-module--text-grey-150--63499";
export var textGrey200 = "TwoImagesWithLogoOption-module--text-grey-200--78d8a";
export var textGrey300 = "TwoImagesWithLogoOption-module--text-grey-300--e0723";
export var textGrey400 = "TwoImagesWithLogoOption-module--text-grey-400--2d55b";
export var textGrey500 = "TwoImagesWithLogoOption-module--text-grey-500--2ba26";
export var textGrey600 = "TwoImagesWithLogoOption-module--text-grey-600--4b30f";
export var textGrey700 = "TwoImagesWithLogoOption-module--text-grey-700--6e69c";
export var textGrey800 = "TwoImagesWithLogoOption-module--text-grey-800--ff3d9";
export var textGrey900 = "TwoImagesWithLogoOption-module--text-grey-900--49c29";