import { graphql } from "gatsby"
import React from "react"

import TwoImagesWithLogoOption from "./TwoImagesWithLogoOption"

export const fragment = graphql`
  fragment TwoImagesWithLogoOptionFragment on WpPage_Flexlayouts_FlexibleLayouts_TwoImagesWithLogoOption {
    sectionHeading
    sectionSubheading
    images {
      image {
        sourceUrl
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP, AVIF]
              width: 700
              placeholder: NONE
            )
          }
        }
      }
      logo {
        link
        logo {
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                width: 230
                placeholder: NONE
              )
            }
          }
        }
      }
    }
    config {
      padding {
        top {
          topMobile
          topTablet
          topDesktop
        }
        bottom {
          bottomMobile
          bottomTablet
          bottomDesktop
        }
      }
    }
  }
`

export const ACFTwoImagesWithLogoOption = ({
  images,
  config,
  sectionHeading,
  sectionSubheading,
}) => {
  return (
    <TwoImagesWithLogoOption
      sectionHeading={sectionHeading}
      sectionSubheading={sectionSubheading}
      images={images}
      config={config}
    />
  )
}
