import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { v4 as uuidv4 } from "uuid"
import { Section, SectionHeading, TextBlock } from "../../UI/Common"

import {
  imageContainer,
  logoContainer,
} from "./TwoImagesWithLogoOption.module.scss"

const TwoImagesWithLogoOption = ({
  images,
  config,
  sectionHeading,
  sectionSubheading,
}) => {
  const { padding } = config || {}
  return (
    <Section padding={padding}>
      <Container className="text-center">
        <SectionHeading text={sectionHeading} alignment="center" />
        {sectionSubheading && <TextBlock text={sectionSubheading} alignment="center" mode="muted" />}
        <Row>
          {images &&
            images.map(item => {
              const {
                image,
                logo: { logo, link },
              } = item              
              return (
                <Col xs={12} lg={6} key={uuidv4()} className="my-3 my-lg-0">
                  <div
                    className={`justify-content-center align-items-center position-relative ${imageContainer}`}>
                    <a href={link} >
                      <GatsbyImage
                        alt={image?.altText || ""}
                        image={getImage(image?.localFile)}
                      />
                      <div className={logoContainer}>
                        <GatsbyImage
                          alt={image?.altText || ""}
                          image={getImage(logo?.localFile)}
                        />
                      </div>
                    </a>
                  </div>
                </Col>
              )
            })}
        </Row>
      </Container>
    </Section>
  )
}

// TwoImagesWithLogoOption.propTypes = {}

export default TwoImagesWithLogoOption
